/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSectionList, StaticSEO } from '@core';
import { useStrapiPageContent } from 'src/content/useStrapiPageContent';
import useGenerateContentBarLabels from 'src/components/composite/chart-generator/components/Guide/useGenerateContentBarLabels';
import { SectionListWithContentBar } from 'src/components/composite/chart-generator/components/Guide/ContentBar';
import { contentBarSyles, guideStyles } from './_guide.styles';

export default function () {
  const strapiPage = useStrapiPageContent('guide');
  const { links, contentWithId } = useGenerateContentBarLabels(strapiPage?.content);

  return (
    <Layout>
      <StaticSEO pageId="guide" />
      <SectionListWithContentBar links={links} sx={contentBarSyles} />
      <StaticSectionList content={contentWithId} sx={guideStyles.section} />
    </Layout>
  );
}
